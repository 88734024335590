export default [
  {
    path: '/login',
    meta: {
      name: 'External',
      requiresAuth: false,
      requiresRole: false,
      props: route => ({ query: route.query.tenant }),
    },
    component: () => import(`@/views/ExternalView.vue`),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import(`@/views/LoginView.vue`),
      },
    ],
  },
  {
    path: '/:area/abschlussarbeit/confirm/:code',
    meta: {
      name: 'Abschlussarbeit',
      requiresAuth: false,
      requiresRole: false,
      props: true,
    },
    component: () =>
      import(`@/views/Bestaetigung/ReferatBestaetigungCardView.vue`),
  },
  {
    path: '/',
    meta: {
      name: 'dashboard-view',
      requiresAuth: true,
      requiresRole: false,
    },
    component: () => import(`@/views/DashboardLayout.vue`),
    children: [
      {
        path: '',
        meta: {
          name: 'Landingpage',
          requiresAuth: true,
          requiresRole: false,
        },
        component: () => import(`@/views/DashboardViews/LandingpageLayout.vue`),
      },
      {
        path: 'user',
        meta: {
          name: 'UserProfile',
          requiresAuth: true,
        },
        component: () => import(`@/views/UserViews/UserProfileView.vue`),
      },
      {
        path: 'exams',
        meta: {
          name: 'MyExamsCard',
          requiresAuth: true,
        },
        component: () => import(`@/views/StudentViews/ExamsCardView.vue`),
      },
      {
        path: 'stundenplan',
        meta: {
          name: 'MyStundenplan',
          requiresAuth: true,
        },
        component: () => import(`@/views/StudentViews/Stundenplan.vue`),
      },
      {
        path: 'notifications',
        meta: {
          name: 'Notifications',
          requiresAuth: true,
        },
        component: () => import(`@/views/DashboardViews/NotificationsView.vue`),
      },
      {
        path: 'bps-apply',
        meta: {
          name: 'BPS-Verwaltung',
          requiresAuth: true,
        },
        component: () => import(`@/views/DashboardViews/BPSStudCardView.vue`),
      },
      {
        path: 'thesis-apply',
        meta: {
          name: 'Abschlussarbeit',
          requiresAuth: true,
        },
        component: () => import(`@/views/DashboardViews/ThesisStudPOAuswahlCardView.vue`),
      },
      {
        path: 'thesis-apply/major/:studiengang_id',
        meta: {
          name: 'Abschlussarbeit',
          requiresAuth: true,
        },
        component: () => import(`@/views/DashboardViews/ThesisStudCardView.vue`),
      },
      {
        path: 'thesis-overview',
        meta: {
          name: 'Abschlussarbeit',
          requiresAuth: true,
        },
        component: () => import (`@/views/DashboardViews/ThesisPrueferView.vue`),
      },
      {
        path: 'bewerbungen/:id',
        meta: {
          name: 'Bewerbungen',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/DashboardViews/BPSManagerportalCardView.vue`),
      },
      {
        path: 'zulassungen/:id',
        meta: {
          name: 'Bewerbungen',
          requiresAuth: true,
        },
        component: () =>
          import(
            `@/views/DashboardViews/BPSManagerportalZulassungenCardView.vue`
          ),
      },
      {
        path: 'bewerbungen/:id/pruefer',
        meta: {
          name: 'Prüferliste',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/DashboardViews/BPSPrueferCardView.vue`),
        props: true,
      },
      {
        path: 'bewerbungen/:id/pruefungstermine',
        meta: {
          name: 'Prüfungstermine',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/DashboardViews/BPSTermineCardView.vue`),
        props: true,
      },
      {
        path: 'applications-card',
        meta: {
          name: 'Applications',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/StudentViews/ApplicationsCardView.vue`),
      },

      {
        path: 'prueferportal',
        meta: {
          name: 'Prüferportal',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/PrueferViews/PrueferportalCardView.vue`),
      },
      {
        path: 'moduladmin/po-list/',
        meta: {
          name: 'Prüfungsordnungen',
          requiresAuth: true,
        },
        component: () => import(`@/views/ModulManagement/POListView.vue`),
      },

      {
        path: 'moduladmin/po_new_version/:id/:prozessTypName/',
        meta: {
          name: 'PODaten & Modulliste (new version)',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/PODetailView.vue`),
        props: true,
      },
      {
        path: 'moduladmin/po-list/:id/',
        meta: {
          name: 'PODaten & Modulliste',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/PODetailView.vue`),
        props: true,
      },
      {
        path: 'moduladmin/po-list/:poId/modul-list/',
        meta: {
          name: 'Moduls',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulListView.vue`),
        props: true,
      },

      {
        path: 'modul-list/', // for Prüfer
        meta: {
          name: 'ModulUnitList',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulUnitListView.vue`),
        props: true,
      },
      {
        path: '/moduls/:id/',
        meta: {
          name: 'ModulDetail',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulDetailView.vue`),
        props: true,
      },
      {
        path: 'clearingadmin/clearingeintrag-list/',
        meta: {
          name: 'Clearingeinträge',
          requiresAuth: true,
        },
        component: () => import(`@/views/ModulManagement/ClearingeintragListView.vue`),
      },
      {
        path: 'clearingadmin/po-list/:poId/modul-list/',
        meta: {
          name: 'Moduls for Clearingadmin',
          requiresAuth: true,
          noScroll: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulListClearingAdminView.vue`),
        props: true,
      },
      {
        path: 'moduladmin/po-list/:poId/modul-list/:id/',
        meta: {
          name: 'Moduldetail',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulDetailView.vue`),
        props: true,
      },
      {
        path: '/403',
        meta: {
          name: 'PermissionDenied',
          requiresAuth: true,
          requiresRole: false,
        },
        component: () => import(`@/components/error/PermissionDenied.vue`),
      },
      {
        path: '/500',
        meta: {
          name: 'ServerError',
          requiresAuth: true,
          requiresRole: false,
        },
        component: () => import(`@/components/error/Error.vue`),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
    meta: {
      requiresAuth: true,
      requiresRole: false,
    },
  },
  // Error component fallback
  {
    path: '/:catchAll(.*)',
    component: () => import(`@/components/error/NotFound.vue`),
    name: 'NotFound',
  },
]
