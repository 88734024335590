/** @format */

// Lib imports
import axios from 'axios'
import store from '../store/index'
import router from '../router/index'

// state set to the previous token, the authorization Axios header set to same
const token = localStorage.getItem('token')
const baseURL = localStorage.getItem('backend_endpoint')
const appVersion = process.env.VUE_APP_VERSION

// creates a new instance that you will call instead of axios.
const restApi = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ${token}`,
    Version: appVersion,
  },
})
// Sets the default global url used by all of this axios instance's requests
const genericApi = axios.create({
  baseURL,
  timeout: 20000,
  headers: {},
})
if (token)
  restApi.defaults.headers.common.Authorization = `Bearer ${token}`

if (baseURL)
  restApi.defaults.baseURL = baseURL

restApi.interceptors.request.use(
  (request) => {
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Add a response interceptor
restApi.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401 && !store.getters.tokenRefreshed) {
        if (store.getters.authorized)
          store.dispatch('refreshtoken')
        else
          return Promise.reject(error)
      }
      else if (error.response.status === 401 && store.getters.tokenRefreshed) {
        router.push('/login').catch(() => { })
      }
      else if (error.response.status === 406 && error.response.data?.detail?.indexOf('Version veraltet') > -1) {
        localStorage.clear()
        delete restApi.defaults.headers.Authorization
        localStorage.setItem('errors', JSON.stringify([{ system: 'requires new version', template: 'RequiresNewVersion' }]))
        window.location.reload(true)
      }
      else if (error.response.status === 403) {
        router.push({ path: '/403' })
      }
    }
    else {
      return Promise.reject(error)
    }

    return Promise.reject(error)
  },
)

export { restApi, genericApi }
