const set = property => (state, dataPayload) => (state[property] = dataPayload)

function toggleDrawerState(state) {
  state.drawerState = true
}

export default {
  setDrawer: set('drawer'),
  setColor: set('color'),
  toggleDrawerState,
}
