// import { createStore } from 'vuex'

import { createStore } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import modules from './modules'

const store = createStore({
  state,
  getters,
  modules,
  mutations,
  actions,
})

export default store
