import CryptoJS from 'crypto-js'

const keyValue = process.env.VUE_APP_CRYPTO_KEY
const ivKey = process.env.VUE_APP_CRYPTO_IV_KEY

export function encryptData(data) {
  if (data) {
    try {
      const key = CryptoJS.PBKDF2(keyValue, 'salt', { keySize: 256 / 32, iterations: 100 })
      const iv = CryptoJS.enc.Utf8.parse(ivKey)
      const encrypted = CryptoJS.AES.encrypt(data, key, { iv, mode: CryptoJS.mode.CBC })
      return encrypted.ciphertext.toString(CryptoJS.enc.Hex)
    }
    catch (error) {
      return data
    }
  }
}

export function decryptData(data) {
  if (data) {
    try {
      const key = CryptoJS.PBKDF2(keyValue, 'salt', { keySize: 256 / 32, iterations: 100 })
      const iv = CryptoJS.enc.Utf8.parse(ivKey)
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(data) }, key, { iv, mode: CryptoJS.mode.CBC })
      return decrypted.toString(CryptoJS.enc.Utf8)
    }
    catch (error) {
      return data
    }
  }
}
