const DarkTheme = {
  name: 'DarkTheme',
  dark: true,
  colors: {
    primary: '#3a72ba',
    secondary: '#5ea1ff',
    tertiary: '#5ea1ff',
  },
}

export { DarkTheme }
